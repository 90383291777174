<template>
  <div class="q-px-md q-pt-md " >

    <tolbars :page="page" :urls="linkss"/> 

    <div class="q-my-xl full-width row wrap justify-center items-center content-center" >

        <q-card class=""  bordered>
        <q-card-section :horizontal="layar($q.screen.lt.md)">
            <q-card-section class="col-md-4 col-sm-12 ">
            <q-form @submit="addData">
                <div class="q-ml-md">
                    <span class="headlist">Pendaftaran?</span> <br>
                    <span>Silahkan masukan informasi Anda</span>
                    <br><br>
                </div>
                <div class="q-mx-md"> 
                    <span>Nama</span>
                    <q-input outlined v-model="dataku.nama" label="Nama Lengkap" dense style="width:100%"/>
                    <span>Username</span>
                    <q-input outlined v-model="dataku.username" label="Username" dense style="width:100%"/>
                    <span>Email</span>
                    <q-input outlined v-model="dataku.email" label="Email" dense style="width:100%"/>
                    <span>Password</span>
                    <!-- <q-input outlined v-model="dataku.password" label="Password" dense style="width:100%"/> -->
                    <q-input v-model="dataku.password" outlined dense :type="isPwd ? 'password' : 'text'" label="Password" >
                      <template v-slot:append> <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer" @click="isPwd = !isPwd" /> </template>
                    </q-input>                    
                    <span>Confirm Password</span>
                    <!-- <q-input outlined v-model="dataku.confirmPassword" label="Confirm Password" dense style="width:100%"/> -->
                    <q-input v-model="dataku.confirmPassword" outlined dense :type="isPwd2 ? 'password' : 'text'" label="Password" >
                      <template v-slot:append> <q-icon :name="isPwd2 ? 'visibility_off' : 'visibility'" class="cursor-pointer" @click="isPwd2 = !isPwd2" /> </template>
                    </q-input>                      
                </div>

                <div class="q-ma-md text-right"> 
                    <q-btn type="submit" outline class="color1"  label="Registrasi" />
                </div>
            </q-form>

                    <!-- <q-btn @click="klikk" outline class="color1"  label="Registrasi" /> -->
                    <div class="bg-primary text-white text-center">{{dasar}}</div>

            </q-card-section>

            <q-separator vertical />

            <q-card-section class="col-md-8 col-sm-12">
                <div class="q-pa-md" style=" ">
                    <div class="headlist" style="text-transform: uppercase;">
                        Pendaftaran Akun
                    </div>
                </div>
                <div class="q-ma-md">
                    Untuk dapat menggunakan fitur PPID, Anda harus memiliki Email Aktif dan Password. Silakan tentukan Email Aktif dan password Anda
                </div>
                <div>
                    <span class="headlist">Ketentuan Pengguna</span>
                </div>
                <div class="q-ma-md">
                    <table>
                        <tr>
                            <td> </td> <td> </td>
                        </tr>
                        <tr style="vertical-align: top;">
                            <td>1</td>
                            <td class="text-justify">Pengguna adalah siapa saja yang memanfaatkan layanan SIP PPID baik sebagai individu maupun lembaga.</td>
                        </tr>
                        <tr style="vertical-align: top;">
                            <td>2</td> <td class="text-justify">Dengan menggunakan Layanan ini Pengguna menyetujui sepenuhnya Persyaratan dan Ketentuan Layanan yang diuraikan di dalam dokumen ini. Jika Pengguna tidak menyetujui Persyaratan dan Ketentuan Layanan ini, harap jangan gunakan Layanan ini.</td>
                        </tr>
                        <tr style="vertical-align: top;" >
                            <td colspan="2">  <span class="headlist" >Ketentuan Pengguna</span> </td>
                        </tr>
                        <tr style="vertical-align: top;" >
                            <td  >  </td>
                            <td class="text-justify" >Seluruh sumber data dan informasi yang disimpan/direkam/diolah pada Layanan ini termasuk validasi dan verifikasi keabsahannya sepenuhnya menjadi tanggung jawab dari Pengguna Layanan. Operasional pengelolaan seluruh data dan informasi mencakup pemutakhiran data dan informasi dimaksud sepenuhnya menjadi tanggung jawab dari Pengguna sesuai hak akses masing-masing. Pengguna dilarang mendistribusikan data dan informasi dari Layanan ini kepada pihak ketiga kecuali telah mendapat ijin resmi dan tertulis dari Pengelola.Pengguna berkewajiban memahami dan mematuhi Kebijakan Privasi Data dan Informasi dan Kebijakan Keamanan yang ditetapkan oleh pengelola. </td>
                        </tr>
                        <tr style="vertical-align: top;" >
                            <td colspan="2">  <span class="headlist" >Kebijakan Privasi Data & Informasi</span> </td>
                        </tr>
                        <tr style="vertical-align: top;" >
                            <td>  </td>
                            <td class="text-justify">Pengelola akan mencatat informasi tentang semua aktifitas dari pengguna. Mencakup semua transaksi data dan informasi dari atau antar pengguna. Pengelola juga dapat mengumpulkan data dan informasi tentang pengguna dari pengguna lain. Hal ini untuk memberikan pengalaman yang lebih baik dalam Layanan untuk para pengguna.<br>
                                Penggunaan Layanan dan isinya disediakan dan dikembangkan oleh Pengelola secara berkesinambungan untuk kemudahan pengelolaan data dan informasi sesuai kepentingan dan kebutuhan Pengguna. Oleh sebab itu, pengelolaan dan pemanfaatan data dan informasi yang tersedia merupakan sepenuhnya tanggung jawab dari Pengguna. Tersedia apa adanya dan sebagaimana tersedia, tanpa jaminan jenis apapun dari Pengelola.<br>
                                Pengelola tidak akan dikenakan tanggung jawab atas kerusakan langsung, tidak langsung, tidak disengaja, khusus atau secara konsekuensi, kerugian atau gangguan yang timbul dari penggunaan atau kesalahan informasi yang diberikan oleh Pengguna.<br>
                                Pengguna memahami sepenuhnya terhadap privasi data dan informasi yang mereka kelola. Pemanfaatan data dan informasi serta fasilitas pada Layanan ini sepenuhnya merupakan tanggung jawab pengguna.<br>
                            </td>
                        </tr>
                    </table>
                </div>
                <!-- <div class="q-ma-md text-left"> 
                    <q-btn outline class="color1"  label="Registrasi" />
                </div>             -->
            </q-card-section>
        </q-card-section>
        </q-card>        

 
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import { Notify } from 'quasar'
import Joi from "joi";



const schema = Joi.object().keys({
    username: Joi.string().regex(/^[a-zA-Z0-9_]*$/).min(3).max(13).required(),
    password: Joi.string().min(6).required(),
  });


export default {
  data() {
    return {
        

      isPwd:true,
      isPwd2:true,
      page:'Registrasi',
      linkss:'/signup',
      layars:'',

      errorMessage: '',
      dasar: '',

        dataku: {
            id : '',
            username : "",
            nama : '',
            hp: "",
            email: "",
            password : "",
            confirmPassword: "",
            menu_klp : 0
        },
 
    }
  },
  methods: {
      layar(a){
          if (a) {
              return false
          } else {
              return true
          }
      },

      klikk() {
          this.dasar="berhasil"

      },

     addData: function() {


      this.errorMessage = "";
        if (this.validUser()) {
            // Jika user sdh valid lakukan pengiriman data ke server
            // const body = {
            //   username: this.dataku.username,
            //   password: this.dataku.password
            // };
            this.signingUp = true;

            fetch(this.$store.state.url.URL_U_authentikasi+'signup', {
                method: "POST",
                body: JSON.stringify(this.dataku),
                headers: {
                    "content-type": "application/json",
                    // authorization: "kikensbatara " + localStorage.token
                }
            }).then((response) => {
                this.signingUp = false;
                if (response.ok) {
                    // this.$store.commit('notifAdd', 'Menambah')
                    Notify.create({ 
                        position:'center',
                        message: 'Registrasi akun berhasil.!',
                        color: 'primary',
                        },5000)
                    
                    // this.klikk();
                    this.$router.push('/login');
                    // this.getView();
                    // return response.json();
                }
                else{
                  return response.json().then(error => {
                    throw new Error(error.message);
                  });

                }
            })
            .catch((error) => {
              setTimeout(() => {
                this.signingUp = false;
                this.errorMessage = error.message;
              }, 1000);
            });
        }else{
          console.log("tidak valid");

        }

    },

    validUser : function(){
      if (this.dataku.password !== this.dataku.confirmPassword) {
          this.errorMessage = "Password dan Confirm Password harus sama. !";
          Notify.create('Password dan Confirm Password harus sama. !')
          return false;
      }
      // Memulai proses validasi melalui skema Joi yang sebelumnya dibuat
      // req.body merupakan data yang di kirim oleh client dan schema merupakan skema joi yg di buat sebelumnya
      const body = {
              username: this.dataku.username,
              password: this.dataku.password
      };


      const result = schema.validate(body);

      console.log(result.error)

      if (result.error == null || result.error ==undefined) {
          return true;
      }

      if (result.error.message.includes("username")) {
          // jika pesan error yang dihasilkan mengandung char "username" maka
          this.errorMessage = "Username tidak valid (Min : 6 dan Max : 14 Karakter)";
          Notify.create('Username tidak valid (Min : 6 dan Max : 14 Karakter')
      } else {
          this.errorMessage = "Password tidak valid (Min : 6 Karakter)";
          Notify.create('Password tidak valid (Min : 6 Karakter)')
        //   console.log(result.error);

      }
      return false;
    },    

  },
 
 
}
</script>
