<template>
    <div class="bg-color1 text-orange-5"> 
      <q-toolbar inset>
        <q-breadcrumbs active-color="white" style="font-size: 16px">
          <q-breadcrumbs-el label="Home" icon="home" to="/" /> 
          <q-breadcrumbs-el :label="page" :to="{urls}"/>
        </q-breadcrumbs>
      </q-toolbar>
    </div>    
</template>

<script>
export default {
    props:['page', 'urls'],
}
</script>

