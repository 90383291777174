<template>
  <q-layout view="hHh lpR fFf" >

    <headers id="main_menu" />

    <q-page-container >
      <router-view />
      
      <!-- halaman  -->

    </q-page-container>

    <footers/>

  </q-layout>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'LayoutDefault',

  components: {
    // HelloWorld
  },

  data () {
    return {
      leftDrawerOpen: false,
      
      
      
      
      
      navigationPositions: [
        { value: 'top', label: 'top' },
        { value: 'right', label: 'right' },
        { value: 'bottom', label: 'bottom (default)' },
        { value: 'left', label: 'left' }
      ],

      slide: 'style',
      lorem: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque voluptatem totam, architecto cupiditate officia rerum, error dignissimos praesentium libero ab nemo.',
      // tabs 
      tab: '1',
      splitterModel: 20
      // tabs 


    }
  }
}
</script>


