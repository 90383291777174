<template>
    <div class="row justify-center q-ma-md">
        <div class="col-10">
            <q-card flat bordered class="my-card">
            <div class="q-ma-md">
                <!-- <div class="text-h6 text-weight-bolder">404</div> -->
            </div>
 
    <div class="column items-center justify-center" style="height: 150px">
 
      <div class="col">
        <!-- <div class="text-h6 text-weight-bolder">404</div> -->
      </div>
      <div class="col">
        <div class="text-h6 text-weight-bolder">404</div>
      </div>
      <div class="col">
        Halaman sedang dalam maintenance...
      </div>
    </div>


 

 
            </q-card>
            
        </div>
    </div>
</template>

<script>
 
 
 


export default {
 
    
data() {
    return {
        currentPage: 0,
        pageCount: 0,

            page_first  : 1,
            page_last   : 0,
            page_limit  : 10,
            cari        : '',
            list_data   : [],
            mdl_lihat   : false,
             


            create_at         : '',
            createby         : '',
            deskripsi        : '',
            editAt           : '',
            file             : '',
            id               : '',
            isi              : '',
            judul            : '',
            TProdukHukum     : '',

            tipe_produk_hukum:[
              {id:1, uraian:'Peraturan Daerah'},
              {id:2, uraian:'Peraturan Bupati'},
              {id:3, uraian:'Keputusan Bupati'},
              {id:4, uraian:'Instruksi Bupati'},
              {id:5, uraian:'Keputusan Kepala SKPD'},
              {id:6, uraian:'Standar Operasional Prosedur'},
              {id:7, uraian:'Dokumen Lainnya'},
              ],            

    }
},
methods: {
 
},
mounted() {
     
},
    
    
}
</script>