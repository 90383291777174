<template>
    <div class="row justify-center q-ma-md">
        <div class="col-10">
            <q-card flat bordered class="my-card">
            <div class="q-ma-md">
                <div class="text-h6 text-weight-bolder">LAPORAN KINERJA</div>
            </div>
 
  <div class="q-pa-md" style="max-width: 100%">
    <q-list bordered>
      <q-item clickable v-ripple>
 

        <q-item-section>      
            <q-input color="primary" v-model="cari" label="Cari Data" @keyup="getView()">
                <template v-slot:prepend>
                <q-icon name="search" />
                </template>
            </q-input> 
      </q-item-section> 

      </q-item>
    </q-list>

    <q-list bordered v-for="data in list_data" :key="data.id"> 
      <q-item @click="mdl_lihat=true, getData(data)" clickable v-ripple>
        <q-item-section avatar>
          <q-avatar rounded color="primary" text-color="white" icon="picture_as_pdf" />
        </q-item-section>  
        <q-item-section>
          <q-item-label> <b>{{data.judul}}</b> </q-item-label>
          <q-item-label caption lines="1" v-html="data.isi"></q-item-label>
        </q-item-section> 
      </q-item> 
    </q-list>

    <div class="q-pa-lg flex flex-center">
        <q-pagination
        @click="getView()"
        v-model="page_first"
        :max="page_last"
        input
        />
    </div>

    <q-dialog v-model="mdl_lihat" full-width>
      <q-card >
        <q-toolbar>
          <q-avatar rounded color="primary" text-color="white" icon="picture_as_pdf" />

          <q-toolbar-title><span class="text-weight-bold">Lihat</span> Data</q-toolbar-title>

          <q-btn flat round dense icon="close" v-close-popup @click="mdl_lihat=false, clearData()" />
        </q-toolbar>

        <q-card-section>
          <b>{{judul}}</b><br>
          <p>Post🪧 {{UMUM.tglConvert(createAt).tgl}}</p> <br>
          <p  v-html="isi"></p>
        </q-card-section> 
 
        <div class="q-ma-md">
            <object :data="$store.state.url.URL_UPLOAD+file" type="application/pdf" style="height: 100vh; width: 100%">
                <p>Alternative text - include a link <a :href="$store.state.url.URL_UPLOAD+file">to the PDF!</a></p>
            </object>        
        </div>
 
        
      </q-card>
    </q-dialog>

  </div>


 

 
            </q-card>
            
        </div>
    </div>
</template>

<script>
 
 
import UMUM from '../../../public/library/umum';


export default {
 
    
data() {
    return {
        currentPage: 0,
        pageCount: 0,

            page_first  : 1,
            page_last   : 0,
            page_limit  : 10,
            cari        : '',
            list_data   : [],
            mdl_lihat   : false,
            UMUM   : UMUM,


            createAt         : '',
            createby         : '',
            deskripsi        : '',
            editAt           : '',
            file             : '',
            id               : '',
            isi              : '',
            judul            : '',

    }
},
methods: {
      getView : function() { 
        fetch(this.$store.state.url.URL_ITB + "laporanKinerja", {
            method: "POST",
            headers: {
              "content-type": "application/json",
            //   authorization: "kikensbatara " + localStorage.token
            },
            body:JSON.stringify({ 
              cari : this.cari,
              data_ke: this.page_first,
              page_limit : this.page_limit,
            })
        })
            .then(res => res.json())
            .then(res_data => {
              this.list_data  = res_data.data; 
              this.page_last  = res_data.jml_data;
            //   console.log(res_data);
            }); 
      },
      getData(data){
        console.log(data);

        this.createAt           =   data.createAt
        this.createby           =   data.createby
        this.deskripsi          =   data.deskripsi
        this.editAt             =   data.editAt
        this.file               =   data.file
        this.id                 =   data.id
        this.isi                =   data.isi
        this.judul              =   data.judul 
      },
    clearData(){ 
        this.createAt           =   ''
        this.createby           =   ''
        this.deskripsi          =   ''
        this.editAt             =   ''
        this.file               =   ''
        this.id                 =   ''
        this.isi                =   ''
        this.judul              =   '' 
      }
},
mounted() {
    this.getView()
},
    
    
}
</script>