import { render, staticRenderFns } from "./headers.vue?vue&type=template&id=341b80e0&"
import script from "./headers.vue?vue&type=script&lang=js&"
export * from "./headers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QRouteTab from 'quasar/src/components/tabs/QRouteTab.js';
import QTab from 'quasar/src/components/tabs/QTab.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QHeader,QToolbar,QAvatar,QSpace,QBtnDropdown,QList,QItem,QItemSection,QSeparator,QExpansionItem,QTabs,QRouteTab,QTab});qInstall(component, 'directives', {Ripple});
