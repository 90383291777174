import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './quasar'



import Footers from './components/footers.vue'
Vue.component('footers', Footers)

import Headers from './components/headers.vue'
Vue.component('headers', Headers)

import Tolbars from './components/toolbar.vue'
Vue.component('tolbars', Tolbars)

import sidebar from './components/sidebar.vue'
Vue.component('sidebar', sidebar)



Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
