import Vue from 'vue'
import Vuex from 'vuex'

import Notify from 'quasar/src/plugins/Notify.js';

Vue.use(Vuex)

// var URL = 'http://localhost:5014/';
// var URL = 'http://103.18.117.102:5017/';
// var URL = 'http://10.91.178.2:5017/';
// var URL = 'http://localhost:5019/';
// var URL = 'http://server-ppid.konaweselatankab.go.id/';
var URL = 'https://server-ppid.konaweselatankab.go.id/'; 

export default new Vuex.Store({

  state: {

    URLX : URL,
    url: {
      URL_APP: URL,
      URL_UPLOAD              : URL + 'uploads/',
      URL_DASHBOARD           : URL + 'api/v1/dashboard/',
      URL_DM_MENU             : URL + 'api/v1/menuList/',
      URL_DM_KLP_USERS        : URL + 'api/v1/kelompokUsers/',
      URL_DM_REGISTRASI       : URL + 'api/v1/registrasi/',
  
      URL_simpeg_instansi     : URL + 'api/v1/simpeg_instansi/',
      URL_simpeg_unit_kerja   : URL + 'api/v1/simpeg_unit_kerja/',
      URL_simpeg_biodata      : URL + 'api/v1/simpeg_biodata/',
  
  
      URL_PESAN               : URL + 'api/v1/pesan/',
      URL_U_authentikasi      : URL + 'api/v1/u_authentikasi/',
      URL_PROFILE             : URL + 'api/v2/profile/',
      URL_SLIDE               : URL + 'api/v2/slider/',

      URL_LOGIN               : URL + 'auth/login_umum',

      URL_ITB                 : URL + 'api/itb/',
      URL_ITSS                : URL + 'api/itss/'
    },

    pesan :'',
    warna :'',
    icon  :'',

  },
  mutations: {
    notif(state){
      Notify.create({
        message: state.pesan,
        color: state.warna,
        icon: state.icon,
        timeout: 500,
      })
    },
    notif_add(){
      Notify.create({
        message: 'Berhasil menambah data',
        color: 'green',
        icon: 'fa fa-check',
        timeout: 500,
      })
    },
 
  },
  actions: {
  },
  modules: {
  }
})
