<template>
  <div class="q-pt-xl" >
 
    <q-layout view="lHh Lpr lff" style="height: 600px" class="">
      <q-header elevated class="bg-color1">
        <q-toolbar>
          <q-toolbar-title class="txHedBar">PPID KONAWE SELATAN</q-toolbar-title>
          <q-btn flat @click="drawer = !drawer" round dense icon="menu" />
        </q-toolbar>
      </q-header>

      <q-drawer
        v-model="drawer"
        show-if-above
        :width="250"
        :breakpoint="400"
      >
        <q-scroll-area style="height: calc(100% - 150px); margin-top: 150px; border-right: 1px solid #ddd">
        <q-tabs
          v-model="tab"
          vertical
          class="text-teal"
        > 

          <q-tab name="inbox" style="justify-content:left !important"  @click="getView_Masuk">
              <q-item class="text-left">
                  <q-item-section avatar><q-avatar class="bg-color1" text-color="white" icon="inbox" /></q-item-section>
                  <q-item-section class="judullist">Kotak Masuk</q-item-section>
              </q-item>
          </q-tab>        
          <q-tab name="outbox" style="justify-content:left !important" @click="getView_Keluar">
              <q-item class="text-left">
                  <q-item-section avatar><q-avatar class="bg-color1" text-color="white" icon="send" /></q-item-section>
                  <q-item-section class="judullist">Kotak Keluar</q-item-section>
              </q-item>
          </q-tab>        
          <!-- <q-tab name="profile" style="justify-content:right !important">
              <q-item class="text-left">
                  <q-item-section avatar><q-avatar class="bg-color1" text-color="white" icon="star" /></q-item-section>
                  <q-item-section class="judullist">Profile</q-item-section>
              </q-item>
          </q-tab>         -->
          <q-tab name="logout" style="justify-content:left !important" @click="logout">
              <q-item class="text-left">
                  <q-item-section avatar><q-avatar class="bg-color1" text-color="white" icon="send" /></q-item-section>
                  <q-item-section class="judullist">Keluar</q-item-section>
              </q-item>
          </q-tab>         
        </q-tabs>

          <!-- <q-list padding>
            <q-item clickable v-ripple>
              <q-item-section avatar>
                <q-icon name="inbox" />
              </q-item-section>

              <q-item-section>
                Drafts
              </q-item-section>
            </q-item>

            <q-item clickable v-ripple>
              <q-item-section avatar>
                <q-icon name="star" />
              </q-item-section>

              <q-item-section>
                Profile
              </q-item-section>
            </q-item>

            <q-item clickable v-ripple>
              <q-item-section avatar>
                <q-icon name="send" />
              </q-item-section>

              <q-item-section>
                Logout
              </q-item-section>
            </q-item>

 
          </q-list> -->
        </q-scroll-area>

        <q-img class="absolute-top" src="https://cdn.quasar.dev/img/material.png" style="height: 150px">
          <div class="absolute-bottom bg-transparent text-center">
            <q-avatar size="55px" class="q-mb-sm">
              <!-- <img src="https://cdn.quasar.dev/img/boy-avatar.png"> -->
            </q-avatar>
            <div><span>Username</span></div>
            <div class="text-weight-bold">{{username}}</div>
          </div>
        </q-img>
      </q-drawer>

      <q-page-container style="padding-top:unset !important;">
        <q-page>
        <q-tab-panels
          v-model="tab"
          animated
          swipeable
          vertical
          transition-prev="jump-up"
          transition-next="jump-up"
        >
          <q-tab-panel name="inbox">

            <div class="row justify-between">
              <div class="col-6">
                <div class="text-h5 q-mb-md">Kotak Masuk</div>

              </div>
              <div class="col-6 text-right">
                <!-- <div class="q-mb-md">
                  <q-btn color="primary" icon="mail" label="On Left" />
                </div> -->
              </div>

            </div>
            <div class="row">
              <div class="col-12"> 

                <q-markup-table>
                  <thead>
                    <tr>
                      <td>No</td>
                      <td>Email</td>
                      <td>Pesan</td>
                      <td>Waktu</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in list_data_masuk" :key="index">
                      <td>{{index+1}}</td>
                      <td>{{data.email}}</td>
                      <td>{{data.judul}}</td>
                      <td>{{UMUM.tglConvert(data.waktu).tgl}}</td>
                      <td>
                        <q-btn size="sm" color="blue" round dense :icon="'visibility'" @click="selectData(data), lihat = true" /> | 
                        <q-btn size="sm" color="red" round dense :icon="'delete'" @click="removeData(data.id)" disable />
                      </td>
                    </tr> 
                  </tbody>
                </q-markup-table>

              </div>

            </div>
            <div class="row justify-center">
              <div class="">
                <div class="q-mt-md">
                  <q-pagination
                    v-model="page_first_masuk"
                    :max="page_last_masuk" 
                    direction-links
                    @input="getView_Masuk()"
                  />
                </div>      
              </div>
            </div>            
          </q-tab-panel>

          <q-tab-panel name="outbox">
            <div class="row justify-between">
              <div class="col-6">
                <div class="text-h5 q-mb-md">Kotak Keluar</div>

              </div>
              <div class="col-6 text-right">
                <div class="q-mb-md">
                  <q-btn color="primary" icon="mail" label="Kirim Pesan" @click="alert = true" />
                </div>
              </div> 
            </div>

            <div class="row">
              <div class="col-12"> 

                <q-markup-table>
                  <thead>
                    <tr>
                      <td>No</td>
                      <td>Email</td>
                      <td>Subject</td>
                      <td>Waktu</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in list_dataKeluar" :key="index">
                      <td>{{index+1}}</td>
                      <td>{{data.email}}</td>
                      <td>{{data.judul}}</td>
                      <td>{{UMUM.tglConvert(data.waktu).tgl}}</td>
                      <td>
                        <q-btn size="sm" color="blue" round dense :icon="'visibility'" @click="selectData(data), lihat = true" /> | 
                        <q-btn size="sm" color="red" round dense :icon="'delete'" @click="removeData(data.id, data.fileOld)" />
                      </td>
                    </tr> 
                  </tbody>
                </q-markup-table>          

              </div>
            </div>
            <div class="row justify-center">
              <div class="">
                <div class="q-mt-md">
                  <q-pagination
                    v-model="page_first"
                    :max="page_last" 
                    direction-links
                    @input="getView_Keluar()"
                  />
                </div>      
              </div>
            </div>
          </q-tab-panel>

          <q-tab-panel name="profile">
            <div class="text-h4 q-mb-md">Movies</div>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis praesentium cumque magnam odio iure quidem, quod illum numquam possimus obcaecati commodi minima assumenda consectetur culpa fuga nulla ullam. In, libero.</p>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis praesentium cumque magnam odio iure quidem, quod illum numquam possimus obcaecati commodi minima assumenda consectetur culpa fuga nulla ullam. In, libero.</p>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis praesentium cumque magnam odio iure quidem, quod illum numquam possimus obcaecati commodi minima assumenda consectetur culpa fuga nulla ullam. In, libero.</p>
          </q-tab-panel>
          <q-tab-panel name="logout">
            <div class="text-h4 q-mb-md">Movies</div>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis praesentium cumque magnam odio iure quidem, quod illum numquam possimus obcaecati commodi minima assumenda consectetur culpa fuga nulla ullam. In, libero.</p>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis praesentium cumque magnam odio iure quidem, quod illum numquam possimus obcaecati commodi minima assumenda consectetur culpa fuga nulla ullam. In, libero.</p>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis praesentium cumque magnam odio iure quidem, quod illum numquam possimus obcaecati commodi minima assumenda consectetur culpa fuga nulla ullam. In, libero.</p>
          </q-tab-panel>
        </q-tab-panels>   

      <!-- ============================================= MODAL ADD DATA ============================== -->
          <q-dialog v-model="alert" persistent >
            <q-card style="width:800px">
              <form @submit="addData">
                <q-card-section class="text-center">
                  <div class="color5">Kirim Pesan</div>
                </q-card-section>

                <q-card-section class="q-pt-md">
                  <div class="q-gutter-md">
                    <q-input class="hidden" square outlined v-model="form.ke" label="To (Tujuan)" dense required />
                    <q-input class="hidden" square outlined v-model="form.dari" label="From (Dari)" dense disable required/>
                    <q-input outlined v-model="form.judul" label="Subject" dense  required/> 
                    <q-editor v-model="form.isi" min-height="5rem" required />
                    <q-file outlined v-model="form.file" :dense="true" label="File Lampiran">
                      <template v-slot:prepend>
                        <q-icon name="attach_file" />
                      </template>
                    </q-file>
                  </div>  
                </q-card-section>

                <q-card-actions align="right" class="bg-grey-2">
                  <q-btn type="submit" flat label="Kirim" color="primary" />
                  <q-btn flat label="Kembali"   class="color5" @click="alert=false"  />
                </q-card-actions>
              </form>
            </q-card>
          </q-dialog>
      <!-- ============================================= MODAL ADD DATA ============================== -->

      <!-- ============================================= MODAL LIHAT DATA ============================== -->
        <q-dialog v-model="lihat">
          <q-card style="width:800px">
            <q-card-section class="row items-center q-pb-none">
              <div class="text-h6">Pesan</div>
              <q-space />
              <q-btn icon="close" flat round dense v-close-popup @click="close" />
            </q-card-section>

            <q-card-section>
              <div class="text-h6">{{form.judul}}</div>
              <small>Dari <u>{{form.email}}</u> | {{UMUM.tglConvert(form.waktu).tgl }}</small> <br>
              <hr>
              <div class="">
                <p v-html="form.isi"> </p> 
              </div>
              <!-- <p>{{form.file}}</p> -->
              
              <!-- <div v-if="kondisifile(form.file)"> -->
              <div v-if="form.file == '' || form.file == null || form.file == undefined || form.file == 'null' " > 
              </div>
              <div v-else>
                <q-btn color="secondary" icon-right="visibility" label="Lampiran File" @click="downloadfile($store.state.url.URL_APP+'uploads/'+form.file)" />
                <!-- {{$store.state.url.URL_APP+'uploads/'+form.file}} -->
                <!-- <q-btn size="sm" color="blue" round dense :icon="'visibility'" @click="lihatFile=true"  /> -->
              </div>
            </q-card-section>
 
          </q-card>
        </q-dialog>      

 
      <!-- ============================================= MODAL LIHAT DATA ============================== -->

        </q-page>
      </q-page-container>
      
    </q-layout>
  
 
  </div>
</template>

<style scoped>

.q-page-container{
  padding-top:50px !important;
} 

tr:nth-child(even) {
  background-color: #3498db14 !important;
}

 
</style>

<script> 

import UMUM from  '../../public/library/umum'
import {Notify} from 'quasar' 

// var uniqid = require('uniqid');
import uniqid from 'uniqid';

 


 



export default { 
  
  data() {
    return {
      // MODAL  
      lihat:false,
      lihatFile:false,
      // MODAL  
      tab: 'inbox', 
      alert:false, 
      
      layars:'', 
      drawer: false,
      // menuList: menuList,

      // ============ user 
      username: '',
      // ============ user 

      // form
      tujuan:'',
      subject:'',
      isi:'',

      list_dataKeluar:[],
      list_data_masuk:[],
      muncul : false,

      form:{ 

        dari     :'',
        email    :'',
        file     :'',
        fileOld  :'',
        id       :'',
        isi      :'',
        judul    :'',
        ke       :'-',
        nama     :'',
        status   :0, 
        userid   :'',
        username :'',
        waktu    :'', 


      },
      
      


      UMUM:UMUM,

      page_first: 1,
      page_last: 0,
      page_limit : 10, 

      page_first_masuk  : 1,
      page_last_masuk   : 0,
      page_limit_masuk  : 10, 

      notifikasi:Notify,

 
    }
  },
  methods: {
    layar(a){
        if (a) {
            return false
        } else {
            return true
        }
    },


    getView_Keluar : function() {
      // console.log(a);
      var profil = JSON.parse(localStorage.profile)
      this.form.dari = profil._id 
      // this.form.createdAt = UMUM.NOW()
      fetch(this.$store.state.url.URL_PESAN + "view_umum", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body:JSON.stringify({
            dari:this.form.dari,
            data_ke: this.page_first,
            page_limit : this.page_limit,
          })
      })
          .then(res => res.json())
          .then(res_data => { 
            this.list_dataKeluar  = res_data.data; 
            this.page_last        = res_data.jml_data;
            // console.log(res_data);
          }); 
    },  
    
    getView_Masuk : function() {

      var profil = JSON.parse(localStorage.profile)
      var ke = profil._id      
      //       this.form.dari
      // this.form.ke
      console.log(ke);
      // this.form.createdAt = UMUM.NOW()
      
      fetch(this.$store.state.url.URL_PESAN + "kotakMasuk_U", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body:JSON.stringify({
            ke          : ke,
            data_ke     : this.page_first_masuk,
            page_limit  : this.page_limit_masuk,
          })
      })
          .then(res => res.json())
          .then(res_data => { 
            this.list_data_masuk        = res_data.data; 
            this.page_last_masuk        = res_data.jml_data;
            // console.log(res_data);
          }); 
    },   

    addData : function() {
      console.log(this.form);
      // this.form.createdAt = UMUM.NOW()
          this.form.id = uniqid()
          var formData = new FormData();
          formData.append("data", JSON.stringify(this.form)); 
          formData.append("file", this.form.file);       
      fetch(this.$store.state.url.URL_PESAN + "addData_umum", {
          method: "POST",
          headers: {
            // "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          // body: JSON.stringify(this.form)
          body: formData
      }).then(() => {
          this.tab = 'outbox'  ;
          this.$store.commit('notif_add') 
          this.getView_Keluar();
          
      });
    },    

    selectData:function(data){
      console.log(data); 

      this.form.dari     = data.dari
      this.form.email    = data.email
      this.form.file     = data.file
      this.form.fileOld  = data.fileOld
      this.form.id       = data.id
      this.form.isi      = data.isi
      this.form.judul    = data.judul
      this.form.ke       = data.ke
      this.form.nama     = data.nama
      this.form.status   = data.status
      this.form.userid   = data.userid
      this.form.username = data.username
      this.form.waktu    = data.waktu
      
      
    },

    close:function(){ 

      this.form.dari     = ''
      this.form.email    = ''
      this.form.file     = ''
      this.form.fileOld  = ''
      this.form.id       = ''
      this.form.isi      = ''
      this.form.judul    = ''
      this.form.ke       = ''
      this.form.nama     = ''
      this.form.status   = ''
      this.form.userid   = ''
      this.form.username = ''
      this.form.waktu    = ''
      
      
    },


    removeData : function(idnya, fileOld) { 

      Notify.create({
        message: 'Anda yakin ingin menghapus data ini?',
        color: 'negative',
        icon: 'fa fa-trash',
        actions: [
          { label: 'Hapus', color: 'yellow', handler: () => {
            
            console.log(idnya);

              fetch(this.$store.state.url.URL_PESAN + "remove", {
                  method: "POST",
                  headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                  },
                  body: JSON.stringify({
                    id      : idnya,
                    fileOld : fileOld
                  })
              }).then(() => {
                  this.tab = 'outbox'  
                  this.getView_Keluar();
              });

          } },
          { label: 'Kembali', color: 'white', handler: () => { /* ... */ } }
        ]        
      })

      
 

    },

    // ===================================================== CONFIG  
    NotifyEditable: function (){
      this.$store.commit('notif', 
        this.$store.state.pesan='Memilih Data',
        this.$store.state.warna='green',
        this.$store.state.icon='fa fa-check'
        );
    },

    kondisifile: function(filenya){
      if (filenya != null || filenya != 'null' || filenya != '' || filenya != undefined) {
        return this.muncul = false
      } else {
        return this.muncul = true
      }
    },

    downloadfile: function(filenya){
      window.open(filenya);

    },

    // ===================================================== CONFIG 



    logout: function() {
      localStorage.removeItem("token");
      localStorage.removeItem("profile");
      localStorage.removeItem("menu");
      this.$router.push("/login");
      location.reload();
    },

    fungsi_tab: function(){
      

      var profil = JSON.parse(localStorage.profile)
      // console.log(profil._id);

      if (this.tab == 'inbox') {
        this.form.dari = profil._id
        this.getView_Masuk();
      } else if (this.tab == 'outbox') {
        this.form.ke = profil._id
        this.getView_Keluar();
      } 
    }

    
  },

  mounted() {
      var element = document.getElementById('main_menu');
      element.parentNode.removeChild(element);  
      var profil = JSON.parse(localStorage.profile)
      // console.log(profil._id);
      // this.form.dari = profil._id
      this.username = profil.username;

      // this.fungsi_tab();
      this.getView_Keluar();
      this.getView_Masuk();
  },
 
 
}
</script>
