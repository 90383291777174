<template>
  <div>
      <div class=""> 
        <q-carousel
          v-model="slide"
          swipeable
          animated
          :padding="true"
          :vertical="false"
          :arrows="arrows" 
          infinite
          :navigation-position="navPos"
          :autoplay="true"
          class="bg-black text-white"
        >
 <!-- this.$store.state.URLX -->
          <q-carousel-slide v-for="slide in list_slide" :key="slide.id" :name="slide.file" :img-src="$store.state.URLX+'uploads/'+slide.file" style="width:100%;" class="column no-wrap flex-right text-center">
 
          </q-carousel-slide>
       

 
        </q-carousel>
      </div>
 
      <q-tabs
        v-model="tab"
        inline-label
        :breakpoint="0"
        align="justify"
        class="bg-color1 text-white shadow-2"
      >
        <q-tab name="1" label="Daftar Informasi Publik" />
        <q-tab name="2" label="Permohonan Informasi" />
        <q-tab v-if="$q.screen.gt.sm" name="3" label="Laporan Pelayanan" />
        <q-tab v-if="$q.screen.gt.sm" name="4" label="Layanan Kepuasan Masyarakat" />
        <q-btn-dropdown v-if="$q.screen.lt.md" auto-close stretch flat label="More...">
          <q-list>
            <q-item clickable @click="tab = '3'">
              <q-item-section>Laporan Pelayanan</q-item-section>
            </q-item>

            <q-item clickable @click="tab = '4'">
              <q-item-section>Layanan Kepuasan Masyarakat</q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </q-tabs>
      <!-- <div v-if="$q.screen.gt.sm" class="q-ma-sm"> -->
      <div v-if="layar" :class="cssmargin">
        <div class="fit row wrap justify-center items-start content-start">
          <template>
            <q-tab-panels v-model="tab" animated vertical transition-prev="jump-up" transition-next="jump-up" style="width:800px" >

              <q-tab-panel name="1">
                <div class="headlist q-mb-md text-center">Daftar Informasi Publik</div>
                <p class="text-justify font1">Salah satu kewajiban badan publik yang dinyatakan dalam Undang-Undang No 14 Tahun 2008 adalah menyediakan Daftar Informasi Publik (DIP). DIP adalah catatan yang berisi keterangan sistematis tentang informasi publik yang berada dibawah penguasaan badan publik. Melalui aplikasi PPID yang digunakan ini, badan publik dapat mempublikasi informasi yang dikuasai yang selanjutnya tersusun sebagai DIP secara otomatis..</p>
                 <!-- <q-btn class="bge-color1 text-white" icon="send" label="Selengkapnya" /> -->
              </q-tab-panel>

              <q-tab-panel name="2">
                <div class="headlist q-mb-md text-center">Permohonan Informasi</div>
                <p class="text-justify font1">Setiap orang berhak memperoleh informasi publik sesuai dengan ketentuan Undang-Undang No 14 Tahun 2008 selain itu setiap permohonan informasi publik berhak mengajukan permintaan informasi publik. Melalui aplikasi PPID ini setiap orang dapat mengajukan permohonan informasi secara mudah. Selain itu badan publik dapat memberi respon permohonan informasi yang diajukan sesuai ketentuan undang-undang.</p>
                 <q-btn to="/login" class="bge-color1 text-white" icon="send" label="Selengkapnya" />
              </q-tab-panel>

              <q-tab-panel name="3">
                <div class="headlist q-mb-md text-center">Laporan Pelayanan</div>
                <p class="text-justify font1">Peraturan Komisi Informasi No. 1 Tahun 2008 tentang Standar Layanan Informasi Publik menyatakan bahwa badan publik memiliki kewajiban membuat dan mengumumkan laporan tentang layanan informasi publik. Melalui aplikasi PPID ini setiap aktifitas terkait pendokumentasian informasi publik serta pelayanan informasi publik akan tercatat dan secara otomatis dan dapat dihasilkan laporan pelayanan informasi publik.</p>
                <q-btn to="/login" class="bge-color1 text-white" icon="send" label="Selengkapnya" />
              </q-tab-panel>
              <q-tab-panel name="4">
                <div class="headlist q-mb-md text-center">Layanan Kepuasan Masyarakat</div>
                <p class="text-justify font1">Partisipasi masyarakat untuk ikut mengawasi, memberikan masukan dan saran untuk peningkatan kualitas pelayanan pengelolaan informasi sangat dibutuhkan. Untuk itu Kementerian Dalam Negeri dan Pemerintah Daerah mengharapkan kesediaan seluruh pemohon dan pengguna informasi publik untuk dapat mengisi survey layanan kepuasan masyarakat.</p>
                 <q-btn to="/login" class="bge-color1 text-white" icon="send" label="Selengkapnya" />
              </q-tab-panel>
              
            </q-tab-panels>
          </template>
        </div>
          
      </div>   
 
 

      <!-- <div class="row justify-center bg-color1">
        <div class="col-sm-12 col-md-10">
            <div v-if="$q.screen.gt.sm"> 
              <div class="q-mt-lg">
                <div class="text-h6 text-left text-white"> <b>Informasi</b> </div>
                <div class="text-caption text-blue-grey-4 text-left">Klik judul informasi untuk melihat</div>
                <q-separator width="190px"  color="orange"  />      
              </div>
            </div>
            <div v-if="$q.screen.lt.md"> 
              <div class="q-mt-lg" >
                <div class="text-h6 text-center text-white"> <b>Informasi</b> </div>
                <div class="text-caption text-blue-grey-4 text-center">Klik judul informasi untuk melihat</div>
                <q-separator width="100%"  color="orange"  />      
              </div>
            </div>    

        </div> 
      </div>       -->
      

      <!-- <div class="row q-gutter-md justify-center q-py-xl bg-color1">
 
        <q-card class=" col-sm-12 col-md-5 no-shadow" bordered>
          <q-card-section class="">
            <q-list padding>
              <q-item-label header class="">
                <div class="text-h4">
                  <q-btn round style="color:#fef9ef;background-color:#227C9D" icon="campaign"></q-btn> 
                  <span class="headlist q-ml-md"><b>Informasi Terbaru</b></span>
                </div> 
              </q-item-label>

              <q-item clickable v-ripple>
                <q-item-section>
                  <q-item-label class="judullist">PENGUMUMAN SELEKSI CPNS DAN PPPK KAB. KONSEL 2021 </q-item-label>
                  <q-item-label caption> 

                    Bersadarkan Keputusan Menteri Pendayagunaan 
                    Aparatur Negara dan Reformasi Birokrasi 
                   
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-separator spaced />
              <q-item clickable v-ripple>
                <q-item-section>
                  <q-item-label class="judullist">SELEKSI CALON PIMPINAN TINGGI PRATAMA SECARA TERBUKA DI LINGKUNGAN PEMERINTAH KAB. KONAWE SELATAN </q-item-label>
                  <q-item-label caption>
                    Dalam rangka seleksi Calon Pimpinan Tinggi 
                    Pratama di Lingkungan Pemerintahan Konawe Selatan 
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-separator spaced />

            </q-list>
          </q-card-section>          
        </q-card>
        <q-card class="col-sm-12 col-md-5 no-shadow" bordered>
          <q-card-section class="">
            <q-list padding>
              <q-item-label header class="headlist">
                <div class="text-h4">
                  <q-btn round  style="color:#fef9ef;background-color:#227C9D" icon="feed"></q-btn> 
                  <span class="headlist q-ml-md"><b>Informasi Paling Banyak Dimohon</b></span>
                </div>
              </q-item-label>

              <q-item clickable v-ripple>
                <q-item-section>
                  <q-item-label class="judullist">PENANGGULANGAN CORONA VIRUS DISEASE 2019 DI KABUPATEN KONAWE SELATAN </q-item-label>
                  <q-item-label caption>
                    Penanggulangan Corona Virus Disease 2019 di Kabupaten Konawe Selatan
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-separator class="" spaced  />              
              <q-item clickable v-ripple>
                <q-item-section>
                  <q-item-label class="judullist">PERLINDUNGAN LAHAN PERTANIAN PANGAN BERKELANJUTAN </q-item-label>
                  <q-item-label caption>
                    Perlindungan Lahan Pertanian Pangan Berkelanjutan
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-separator spaced  />

            </q-list>
          </q-card-section>          
        </q-card>

 
      </div> -->
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  data() {
    return {
      tab: '1',
      lorem: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque voluptatem totam, architecto cupiditate officia rerum, error dignissimos praesentium libero ab nemo.',
      slide: 'style',
      navPos: 'bottom',
      navigation: true,
      arrows: true,
      vertical: false,
      padding: true,
      cssmargin: 'q-ma-xl',

      list_slide:[],
    }
  },
  methods: {


    // URL_SLIDE

    viewSlider : function() {
      
      fetch(this.$store.state.url.URL_SLIDE + "viewss", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            // authorization: "kikensbatara " + localStorage.token
          },
          body:JSON.stringify({
            
          })
      })
          .then(res => res.json())
          .then(res_data => { 
            this.list_slide  = res_data; 
            // this.page_last        = res_data.jml_data;
            console.log(res_data);
          }); 
    },  
        

    layar (){
      if (this.$q.screen.gt.sm){
        this.cssmargin = 'q-ma-xl'
      }else{
        this.cssmargin = ''
      } }
  },

  mounted() {
    this.layar();
    this.viewSlider();
  },
 
 
}
</script>


<style scoped>
 
</style>