import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Profile from '../views/profile.vue'
import Login from '../views/Auth/login.vue'
import Registrasi from '../views/Auth/regist.vue'
import Chat from '../views/chat.vue'
import inbox from '../views/inbox.vue'


import tentang from '../views/informasi_terbuka_berkala/tentang'
import laporan_kinerja from '../views/informasi_terbuka_berkala/laporan_kinerja'
import perjanjian_kinerja from '../views/informasi_terbuka_berkala/perjanjian_kinerja'
import rencana_kerja_tahunan from '../views/informasi_terbuka_berkala/rencana_kerja_tahunan'
import indikator_kinerja_utama from '../views/informasi_terbuka_berkala/indikator_kinerja_utama'
import rencana_strategis from '../views/informasi_terbuka_berkala/rencana_strategis'
import rpjmd from '../views/informasi_terbuka_berkala/rpjmd'

import produk_hukum from '../views/informasi_terbuka_setiap_saat/produk_hukum'
import H404 from '../views/h404'

Vue.use(VueRouter)

function loggedInRedirectDashboard(to, from, next) {
  if (localStorage.token) {
    next('/chat');
  } else {
    next();
  }
}

function isLoggedIn(to, from, next) {
  if (localStorage.token) {
    console.log('Saya masuk')
    next();
  } else {
    next('/login');
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },


  {
    path: '/tentang',
    name: 'tentang',
    component: tentang
  },
  {
    path: '/laporan_kinerja',
    name: 'laporan_kinerja',
    component: laporan_kinerja
  },
  {
    path: '/perjanjian_kinerja',
    name: 'perjanjian_kinerja',
    component: perjanjian_kinerja
  },
  {
    path: '/rencana_kerja_tahunan',
    name: 'rencana_kerja_tahunan',
    component: rencana_kerja_tahunan
  },
  {
    path: '/indikator_kinerja_utama',
    name: 'indikator_kinerja_utama',
    component: indikator_kinerja_utama
  },
  {
    path: '/rencana_strategis',
    name: 'rencana_strategis',
    component: rencana_strategis
  },
  {
    path: '/rpjmd',
    name: 'rpjmd',
    component: rpjmd
  },
  {
    path: '/produk_hukum',
    name: 'produk_hukum',
    component: produk_hukum
  },
  {
    path: '/H404',
    name: 'H404',
    component: H404
  },


  {
    path: '/login',
    name: 'Login',
    component: Login, 
    beforeEnter : loggedInRedirectDashboard
  },
  {
    path: '/signup',
    name: 'Registrasi',
    component: Registrasi, 
    beforeEnter : loggedInRedirectDashboard
  },
  {
    path: '/chat',
    name: 'Chat',
    component: Chat,
    beforeEnter : isLoggedIn
  },
  {
    path: '/inbox',
    name: 'inbox',
    component: inbox,
    beforeEnter : isLoggedIn
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
