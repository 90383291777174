<template>
    <div class="row justify-center q-ma-md">
        <div class="col-10">
            <q-card flat bordered class="my-card">
            <div class="q-ma-md">
                <div class="text-h6 text-weight-bolder">Tentang</div>
            </div>

            <div v-for="data in list_data" :key="data.id">
                <q-separator inset />
    
                <div class="q-pa-md">
                    <b>{{data.judul}}</b>
                </div>
                
                <div class="q-pa-md"> 
                <div v-html="data.isi"></div> 
                <q-img :key="'cover'" :src="$store.state.url.URL_UPLOAD+data.file" style="max-width: 100%; height: auto;" :fit="'cover'" />
                </div>
                <q-separator inset />

            </div>
 

 
            </q-card>
            
        </div>
    </div>
</template>

<script>
export default {
data() {
    return {
            page_first  : 1,
            page_last   : 0,
            page_limit  : 10,
            cari        : '',
            list_data   : [],
    }
},
methods: {
      getView : function() { 
        fetch(this.$store.state.url.URL_ITB + "tentang", {
            method: "POST",
            headers: {
              "content-type": "application/json",
            //   authorization: "kikensbatara " + localStorage.token
            },
            body:JSON.stringify({ 
              cari : this.cari,
              data_ke: this.page_first,
              page_limit : this.page_limit,
            })
        })
            .then(res => res.json())
            .then(res_data => {
              this.list_data  = res_data; 
            //   this.page_last  = res_data.jml_data;
            //   console.log(res_data);
            }); 
      },            
},
mounted() {
    this.getView()
},
    
    
}
</script>