<template>
  <div class="q-px-md q-pt-md">

    <tolbars :page="page" :urls="linkss"/>

    <div class="row q-col-gutter-xs items-center " >
      <div class="col-4" >
      <div v-if="$q.screen.gt.sm"> 
        <div class="q-ma-lg">
          <div class="text-h6 text-left"> <b>Informasi</b> </div> 
          <q-separator width="190px"  color="orange"  />      
        </div>
      </div>
      <div v-if="$q.screen.lt.md"> 
        <div class="q-ma-lg" >
          <div class="text-h6 text-center"> <b>Informasi</b> </div> 
          <q-separator width="100%"  color="orange"  />      
        </div>
      </div>
      </div>
      <div class="col-8" >
        <div class="q-pa-md" style=" ">
            <div class="headlist" style="text-transform: uppercase;">
                <!-- {{tab}}            -->
            </div>
        </div>
      </div>
    </div>

  <div>

      <q-splitter v-model="splitterModel" style="height: 450px" >
        <template v-slot:before>
          <q-tabs v-model="tab" vertical class="text-teal" >
            <template v-for="data in list_data">
              <q-tab :name="data.tab" style="justify-content:right !important" :key="data.id">
                  <q-item class="text-left">
                      <q-item-section avatar><q-avatar class="bg-color1" text-color="white" :icon="data.icon" /></q-item-section>
                      <q-item-section class="judullist">{{data.judul}}</q-item-section>
                  </q-item>
              </q-tab>              
              <!-- <q-tab :name="data.tab" :icon="data.icon" :label="data.judul" :key="data.id" /> -->
            </template> 
          </q-tabs>
        </template>
        
        <template v-slot:after>
          <q-tab-panels v-model="tab" animated swipeable vertical transition-prev="jump-up" transition-next="jump-up" >
          <template v-for="data in list_data2">          
            <q-tab-panel :name="data.tab" :key="data.id">
              <div class="headlist q-mb-md q-pl-xl q-pr-xl">{{data.judul}}</div>
              <div class="q-pl-xl q-pr-xl" v-html="data.isi"></div>
              <!-- <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis praesentium cumque magnam odio iure quidem, quod illum numquam possimus obcaecati commodi minima assumenda consectetur culpa fuga nulla ullam. In, libero.</p> -->
              <!-- <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis praesentium cumque magnam odio iure quidem, quod illum numquam possimus obcaecati commodi minima assumenda consectetur culpa fuga nulla ullam. In, libero.</p> -->
            </q-tab-panel>
          </template>
 
          </q-tab-panels>
        </template>

      </q-splitter>
 
  </div>     

  
 
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  data() {
    return {
      page:'Profile',
      linkss:'/profile',      
      tab: 'Profile',
      splitterModel: 33.4   ,
      // lorem           : 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque voluptatem totam, architecto cupiditate officia rerum, error dignissimos praesentium libero ab nemo.',
      // profil          : 'SIP PPID adalah Sistem Informasi Publik Pejabat Pengelola Informasi dan Dokumentasi atau dapat juga disebut sebagai e-public. SIP PPID atau e-public merupakan aplikasi pengelolaan dan pelayanan informasi untuk PPID yang dikembangkan sesuai Undang-Undang No. 14 Tahun 2008 tentang Keterbukaan Informasi Publik. E-public dirancang dengan platform hybrid - offline dan online, yang terintegrasi antara PPID Pembantu dan PPID Utama dalam sebuah entitas Badan Publik.',
      // tugas_wewenang  : 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque voluptatem totam, architecto cupiditate officia rerum, error dignissimos praesentium libero ab nemo.',
      
      list_data:[],
      list_data2:[],
    }
  },
  // URL_PROFILE
  methods: {
      getView : function() { 
        fetch(this.$store.state.url.URL_PROFILE + "view", {
            method: "POST",
            headers: {
              // "content-type": "application/json",
              // authorization: "kikensbatara " + localStorage.token
            },
            // body:JSON.stringify({ 
              // cari : this.cari,
              // data_ke: this.page_first,
              // page_limit : this.page_limit,
            // })
        })
            .then(res => res.json())
            .then(res_data => {
              this.list_data    = res_data.data; 
              this.list_data2   = res_data.data; 

              this.tab          = res_data.data[0].tab
              // this.list_tab  = res_data.data; 
              // this.page_last  = res_data.jml_data;

              console.log(this.tab);
              // console.log(typeof this.list_tab);
            }); 
      },
  },
  mounted() {
    this.getView()
  },
 
 
}
</script>
