<template>
  <div class="q-px-md q-pt-md">

    <tolbars :page="page" :urls="linkss"/> 

    <div class="q-my-xl full-width row wrap justify-center items-center content-center" >

        <q-card class=""  bordered>
            <q-card-section :horizontal="layar($q.screen.lt.md)">
                <q-card-section class="col-md-4 col-sm-12 ">

                <div class="q-ml-md">
                    <span class="headlist">Sudah Punya Akun?</span> <br>
                    <span>Silahkan masukan email dan password Anda</span>
                    <br><br>
                </div>
                <div class="q-mx-md"> 
                    <span>Username</span>
                    <q-input outlined v-model="user.username" type="username" label="Username" dense style="width:100%"/>
                    <span>Password</span>
                    <!-- <q-input outlined v-model="user.password" type="password" label="Password" dense style="width:100%"/> -->
                    <q-input v-model="user.password" outlined dense :type="isPwd ? 'password' : 'text'" label="Password" >
                      <template v-slot:append> <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer" @click="isPwd = !isPwd" /> </template>
                    </q-input>
                    <div>{{errorMessage}} </div>
                </div>

                <div class="q-ma-md text-right"> 
                    <q-btn outline class="color1" @click="btn_login" label="Login" />
                </div>
                </q-card-section>

                <q-separator vertical />

                <q-card-section class="col-md-8 col-sm-12">
                    <div class="q-pa-md" style=" ">
                        <div class="headlist" style="text-transform: uppercase;">
                            Selamat datang di SIP PPID
                        </div>
                    </div>
                    <div class="q-ma-md">
                        Selamat datang di SIP PPID
                        Jika anda belum memiliki akun silahkan daftar terlebih dahulu agar anda dapat menggunakan semua fitur-fitur yang ada di sini dengan baik dan bijak.
                    </div>
                    <div class="q-ma-md text-left"> 
                        <q-btn outline class="color1" label="Registrasi" to="/signup"/>
                        <!-- <q-btn outline class="color1" @click="kliks" label="Test" /> -->
                    </div>            
                </q-card-section>
            </q-card-section>
        </q-card>        

 
    </div>
    
 
    
      

 
  </div>
</template>

<script>
// @ is an alias to /src
import { Notify } from 'quasar'

import Joi from "joi";

const schema = Joi.object().keys({
    username: Joi.string().regex(/^[a-zA-Z0-9_]*$/).min(3).max(13).required()
  });


export default {
  data() {
    return {
        

      page:'Permohonan Informasi',
      linkss:'/login',
      tab: 'Mails',
      splitterModel: 33.4   ,
      lorem           : 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque voluptatem totam, architecto cupiditate officia rerum, error dignissimos praesentium libero ab nemo.',
      profil          : 'SIP PPID adalah Sistem Informasi Publik Pejabat Pengelola Informasi dan Dokumentasi atau dapat juga disebut sebagai e-public. SIP PPID atau e-public merupakan aplikasi pengelolaan dan pelayanan informasi untuk PPID yang dikembangkan sesuai Undang-Undang No. 14 Tahun 2008 tentang Keterbukaan Informasi Publik. E-public dirancang dengan platform hybrid - offline dan online, yang terintegrasi antara PPID Pembantu dan PPID Utama dalam sebuah entitas Badan Publik.',
      tugas_wewenang  : 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque voluptatem totam, architecto cupiditate officia rerum, error dignissimos praesentium libero ab nemo.',

      layars:'',
      user:{
          username:'',
          password:'',
      },
      errorMessage:'',
      
      loadingku:false,
      isPwd:true,
 
    }
  },
  methods: {
      kliks(){
          Notify.create('Danger, Will Robinson! Danger!')

      },
      layar(a){
          if (a) {
              return false
          } else {
              return true
          }
      },

      btn_login : function(){


        this.errorMessage = '';
        if(this.validUser()){
          this.loadingku = true;
          const body = {
            username : this.user.username,
            password : this.user.password
          }
          fetch(this.$store.state.url.URL_LOGIN, {
            method : 'POST',
            headers : {
              'content-type' : 'application/json',
            },
            body : JSON.stringify(body),
          }).then((response) => {

            console.log(response);

                if (response.ok) {
                    return response.json();
                    // this.$store.commit("shoWLoading");
                }

                return response.json().then(error => {
                    throw new Error(error.message);
                    // this.$store.commit("shoWLoading");
                });
            })
            .then((result) => {
              console.log("=============================");
              console.log(result);
              // menyimpan token yang tergenerate dari server
              localStorage.token = result.token;
              localStorage.profile = JSON.stringify(result.profile);
              setTimeout(() => {

                this.loadingku = false;
                this.$router.push('/chat');
                // this.loadMenu();
                // location.reload();
              }, 1000);
            })
            .catch(error => {
              setTimeout(() => {
                this.loadingku = false;
                this.errorMessage = error.message;
              }, 1000);
            });
        }
      },


      validUser: function(){

        const body = {
              username: this.user.username
        };

        const result = schema.validate(body);


        if (result.error === null || result.error ==undefined) {
          return true;
        }
        if (result.error.message.includes("username")) {
          this.errorMessage = "Username tidak valid";
        } else {
          this.errorMessage = "Username tidak valid2";
        }
        return false;
      },

  },
 
 
}
</script>
