<template>
    <q-header bordered class="q-pb-md bg-color6 text-white" >
      
    <q-toolbar class="q-py-md bg-color1 text-white shadow-2 rounded-borders"> 
      <q-avatar square size="50px">
        <img src="img/logo.png">
      </q-avatar>
      <span class="q-ml-md">
        <b style="font-size:20px">PPID KONSEL</b><br>
        <span v-if="$q.screen.gt.sm" style="font-size:12px">
          PEJABAT PENGELOLA INFORMASI DAN DOKUMENTASI KABUPATEN KONAWE SELATAN 
        </span>
        <br>

      </span> 
      <q-space />
      
      <q-btn-dropdown v-if="$q.screen.lt.md" stretch flat label="Menu">
        <q-list bordered>

          <q-item clickable v-ripple to="/">
            <q-item-section>Beranda</q-item-section>
          </q-item>
          <q-separator />
          <q-item clickable v-ripple to="/profile">
            <q-item-section>Profile</q-item-section>
          </q-item>
          <q-separator />
          <q-item clickable v-ripple to="/login">
            <q-item-section>Permohonan Informasi</q-item-section>
          </q-item>

        </q-list>         

        <q-expansion-item v-model="expanded" label="Informasi Terbuka Berkala" caption="Lihat selengkapnya" >
          <q-list  class="q-mx-md">  
            <q-item clickable to="/tentang">       
              <q-item-section>Tentang</q-item-section>
            </q-item>
            <q-separator />
            <q-item clickable to="/laporan_kinerja">
              <q-item-section>Laporan Kinerja</q-item-section>
            </q-item>
            <q-separator />
            <q-item clickable to="/perjanjian_kinerja">
              <q-item-section>Perjanjian Kinerja</q-item-section>
            </q-item>
            <q-separator />
            <q-item clickable to="/rencana_kerja_tahunan">
              <q-item-section>Rencana Kerja Tahunan</q-item-section>
            </q-item>
            <q-separator />
            <q-item clickable to="/indikator_kinerja_utama">
              <q-item-section>Indikator Kinerja Utama</q-item-section>
            </q-item>
            <q-separator />
            <q-item clickable to="/rencana_strategis">
              <q-item-section>Rencana Strategis</q-item-section>
            </q-item>
            <q-separator />
            <q-item clickable to="/rpjmd">
              <q-item-section >RPJMD</q-item-section>
            </q-item>
            <q-separator />

          </q-list>
        </q-expansion-item>  

        <q-expansion-item v-model="expanded2" label="Informasi Terbuka Setiap Saat " caption="Lihat selengkapnya" >
          <q-list  class="q-mx-md"> 

            <q-item clickable to="/movies">       
              <q-item-section>Tentang</q-item-section>
            </q-item>
            <q-separator />
            <q-item clickable to="/movies">
              <q-item-section>Berita</q-item-section>
            </q-item>
            <q-separator />
            <q-item clickable to="/movies">
              <q-item-section>Galery </q-item-section>
            </q-item>
            <q-separator />

          </q-list>
        </q-expansion-item> 

      </q-btn-dropdown>

      <q-tabs shrink v-if="$q.screen.gt.sm" >
        <q-route-tab name="Beranda"               to="/"        label="Beranda"               exact  />
        <q-route-tab name="Profile"               to="/profile" label="Profile"               exact  />
        <q-route-tab name="Permohonan Informasi"  to="/login"   label="Permohonan Informasi" exact />
        <!-- <q-tab name="Login" label="Login " exact /> -->

        <q-btn-dropdown auto-close stretch flat label="Informasi Terbuka Berkala">
          <q-list>
            <q-item clickable to="/tentang" @click="tab = 'movies'">
              <q-item-section>Tentang</q-item-section>
            </q-item>

            <q-item clickable to="/laporan_kinerja" @click="tab = 'photos'">
              <q-item-section>Laporan Kinerja</q-item-section>
            </q-item>
 
            <q-item clickable to="/perjanjian_kinerja" @click="tab = 'photos'">
              <q-item-section>Perjanjian Kinerja</q-item-section>
            </q-item>

            <q-item clickable to="/rencana_kerja_tahunan" @click="tab = 'photos'">
              <q-item-section>Rencana Kerja Tahunan</q-item-section>
            </q-item>
            <q-item clickable to="/indikator_kinerja_utama" @click="tab = 'photos'">
              <q-item-section>Indikator Kinerja Utama</q-item-section>
            </q-item>
            <q-item clickable to="/rencana_strategis" @click="tab = 'photos'">
              <q-item-section>Rencana Strategis</q-item-section>
            </q-item>
            <q-item clickable to="/rpjmd" @click="tab = 'photos'">
              <q-item-section>Rencana Pembangunan Jangka Menengah Daerah</q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>    
        <q-btn-dropdown auto-close stretch flat label="Informasi Terbuka Setiap Saat">
          <q-list>
            <q-item clickable to="/produk_hukum" @click="tab = 'movies'">
              <q-item-section>Produk Hukum </q-item-section>
            </q-item>

            <q-item clickable to="/H404" @click="tab = 'photos'">
              <q-item-section>Berita </q-item-section>
            </q-item>
 
            <q-item clickable to="/H404" @click="tab = 'photos'">
              <q-item-section>Galery </q-item-section>
            </q-item>
 
          </q-list>
        </q-btn-dropdown>    

        <!-- <q-btn-dropdown auto-close stretch flat label="Informasi Terbuka Berkala">
          <q-list>
            <q-item clickable @click="tab = 'movies'">
              <q-item-section>Movies</q-item-section>
            </q-item>

            <q-item clickable @click="tab = 'photos'">
              <q-item-section>Photos</q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>       -->

      </q-tabs>

    </q-toolbar>
  
    </q-header>
</template>

<script>
export default {
  data() {
    return {
      expanded: false,
      expanded2: false,
    }
  },
}
</script>