import { render, staticRenderFns } from "./profile.vue?vue&type=template&id=d1caf86e&"
import script from "./profile.vue?vue&type=script&lang=js&"
export * from "./profile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QSplitter from 'quasar/src/components/splitter/QSplitter.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSeparator,QSplitter,QTabs,QTab,QItem,QItemSection,QAvatar,QTabPanels,QTabPanel});
