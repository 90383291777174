<template>
    <div>
        
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    method() {
        
    },
    mounted() {
        
    },
}
</script>