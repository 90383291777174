import { render, staticRenderFns } from "./chat.vue?vue&type=template&id=c88c42b6&scoped=true&"
import script from "./chat.vue?vue&type=script&lang=js&"
export * from "./chat.vue?vue&type=script&lang=js&"
import style0 from "./chat.vue?vue&type=style&index=0&id=c88c42b6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c88c42b6",
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QList from 'quasar/src/components/item/QList.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QEditor from 'quasar/src/components/editor/QEditor.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QSpace from 'quasar/src/components/space/QSpace.js';import Ripple from 'quasar/src/directives/Ripple.js';
import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QHeader,QToolbar,QToolbarTitle,QBtn,QDrawer,QScrollArea,QTabs,QTab,QItem,QItemSection,QAvatar,QList,QIcon,QImg,QPageContainer,QPage,QTabPanels,QTabPanel,QMarkupTable,QPagination,QDialog,QCard,QCardSection,QInput,QEditor,QFile,QCardActions,QSpace});qInstall(component, 'directives', {Ripple,ClosePopup});
